/** ***************************************************************************
 * Apus main script
 *************************************************************************** */

window.addEventListener('DOMContentLoaded', () => {
  class Apus {
    constructor() {
      this._initializers = {};
      this._destroyers = {};
    }

    register(key, initializer, destroyer) {
      this._initializers[key] = initializer;
      this._destroyers[key] = destroyer;
    }

    start() {
      Object.entries(this._initializers).forEach(([key, init]) => init());
    }

    destroy() {
      Object.entries(this._destroyers).forEach(([key, destroy]) => destroy());
    }

    restart() {
      this.destroy();
      this.start();
    }

  }

  window.APUS = new Apus();

  window.dispatchEvent(new Event('apus:init'));

});
